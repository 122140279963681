
import Select from 'react-select';
import '../App.css';

import { Button } from "pier-design-system";

import { Heading } from 'pier-design-system';

import { useEffect, useContext, useMemo} from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Regions, getSpecsRegion, RegionSelectOptions } from '../utilities';

import Header from '../components/Header';
import Footer from '../components/Footer';

import hexVisualOne from '../imgs/hex-visual-1.png';
import hexVisualTwo from '../imgs/hex-visual-2.png';

import { RegionContext } from '../contexts/RegionContext';
import { getGuruCard } from '../guru';


const LandingPage = (props) => {

    const {specsRegion, setSpecsRegion} = useContext(RegionContext);

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const setRegion = (countryCode) => {
        setSpecsRegion(getSpecsRegion(countryCode));
    }

    if (!/#/.test(window.location.href)){
        window.location.href += "#/"
    }

    const navigateToRegion = () => {

        let devModeParam = searchParams.get("dev");
        let linkQuery = devModeParam === "true" ? `?dev=${devModeParam}` : "";

        switch (specsRegion) {
            case Regions.Region_EMEA:
                navigate(`/emea/${linkQuery}`);
                break;

            case Regions.Region_US:
                navigate(`/${linkQuery}`);
                window.location.href = "https://spec-sheet.gumgum.com/";
                // navigate("/us");
                break;

            case Regions.Region_UK:
                navigate(`/uk/${linkQuery}`);
                break;

            case Regions.Region_APAC:
                navigate(`/${linkQuery}`);
                window.location.href = "https://spec-sheet.gumgum.com/";
                // navigate("/apac");
                break;

            case Regions.Region_OTHER:
                navigate("/help");
                break;
        }

    }

    useMemo(()=>{
        // to cache for later use
        getGuruCard("site_copy", (tableData)=>{});

    })

    useEffect(()=>{
        setSpecsRegion(undefined);
        document.querySelector("main").style.opacity = 1;
    }, [])

    return (
        <div className='view-container landing-page'>
         <Header hideSelect={true} sub_title={'Create high impact display ads with everything you need,<br/>all in one place.'}/>
          <main>
            <img className='hex-visual-2' src={hexVisualTwo} alt="" />
            <img className='hex-visual-1' src={hexVisualOne} alt="" />
              <div className="panel choose-country-panel">
                  <Heading size={'sm'}>Choose your campaign's location</Heading>
                  <Select className='choose-country-select' options={RegionSelectOptions} onChange={(obj) => {setRegion(obj.value)}}/>
                  <Button title="Button" pill={true} onClick={navigateToRegion}>Confirm</Button>
              </div>
          </main>
          <Footer/>
      </div>
      )

}

export default LandingPage