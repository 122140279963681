
import './reset.css';
import 'pier-design-system/dist/styles.css';
import './App.css';

import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './views/LandingPage';

import { RegionContext } from './contexts/RegionContext';
import { useSessionStorage } from 'usehooks-ts';

import FinalView from './views/FinalView';
import FormatSelect from './views/FormatSelect';

import regions from './content/regions.json';
import { Regions } from './utilities';
import { getRegionFromURL } from './utilities';
import OtherRegion from './views/OtherRegion';
import { useEffect } from 'react';



function App() {

  const [specsRegion, setSpecsRegion] = useSessionStorage("specs-region", "");

  useEffect(()=>{
    setSpecsRegion(getRegionFromURL());
  }, [setSpecsRegion])

  const getFormats = (regionKey)=>{
    return regions[regionKey].find(it => it.name==="available_formats").value;
  }

  return (
    <div className="App gradient-8">
        <RegionContext.Provider value={{specsRegion, setSpecsRegion}}>
        <HashRouter>
        <Routes>
            <Route path='/' element={<LandingPage/>}/>
            <Route path='/emea' element={<FormatSelect formats={getFormats(Regions.Region_EMEA)}/>}/>
            <Route path='/uk' element={<FormatSelect formats={getFormats(Regions.Region_UK)}/>}/>
            <Route path='/us' element={<FormatSelect formats={getFormats(Regions.Region_US)}/>}/>
            <Route path='/apac' element={<FormatSelect formats={getFormats(Regions.Region_APAC)}/>}/>

            <Route path='/help' element={<OtherRegion/>}/>

            <Route path='/desktop-skin' element={<FinalView formatID="desktop_skin"/>}/>
            <Route path='/mobile-skin' element={<FinalView formatID="mobile_skin"/>}/>
            <Route path='/mobile-scroller' element={<FinalView formatID="mobile_scroller"/>}/>
            <Route path='/inscreen-expandable' element={<FinalView formatID="inscreen_expandable"/>}/>
            <Route path='/inscreen-expandable-video' element={<FinalView formatID="inscreen_expandable_video"/>}/>
            <Route path='/inscreen-expandable-desktop' element={<FinalView formatID="inscreen_expandable_desktop"/>}/>
            <Route path='/inscreen-expandable-mobile' element={<FinalView formatID="inscreen_expandable_mobile"/>}/>
            <Route path='/inimage-expandable' element={<FinalView formatID="inimage_expandable"/>}/>
            <Route path='/6-second-canvas' element={<FinalView formatID="six_second_canvas"/>}/>
            <Route path='/ingame' element={<FinalView formatID="ingame"/>}/>
            <Route path='/hangtime' element={<FinalView formatID="hangtime"/>}/>
            <Route path='/hoverboard' element={<FinalView formatID="hoverboard"/>}/>
          </Routes>
        </HashRouter>
        </RegionContext.Provider>
    </div>
  );
}

export default App;
