import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Button } from 'pier-design-system'

const OtherRegion = () => {
    useEffect(()=>{
        document.querySelector("main").style.opacity = 1;
    }, [])

  return (
    <div className='view-container help'>
    <Header hideSelect={true}/>
    <main>
        <div className="section-help">
            <h1>We're here to support you</h1>
            <div className="content-help">
            <p>For more information about other regions or any help, please contact your GumGum sales representative.</p>
            <Button pill={true} onClick={() => {window.location.href = 'https://gumgum.com/contact'}}>Contact</Button>
            </div>
            
        </div>
    </main>
    <Footer/>
</div>
  )
}

export default OtherRegion