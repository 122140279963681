import {React, useContext, useEffect, useState} from 'react';
import '../App.css';

import { getName, getPlatform } from '../utilities';
import { FeatureContext } from '../contexts/FeatureContext';

import { Tooltip } from 'react-tooltip';

import stepNumber1 from '../imgs/step-number-1.png';
import tierBronze from '../imgs/tier-bronze.png';
import tierSilver from '../imgs/tier-silver.png';
import tierGold from '../imgs/tier-gold.png';
import tierPlatinum from '../imgs/tier-platinum.png';
import toolTip from '../imgs/tooltip_icon.svg';


const TemplateSelect = (props) => {

    const {featureID, setFeatureID} = useContext(FeatureContext);

    const [hoveringPanel, setHoveringPanel] = useState('');

    const [tiersMessage, setTiersMessage] = useState('');

    useEffect(()=>{
        let tiersMess = props.siteCopy.find(item => item.name === "tiers_message")
        setTiersMessage(tiersMess && tiersMess.value || "");
    }, [])

    const selectTemplate = (e)=>{
        setFeatureID(e.target.getAttribute("data-id"));
    }

    // const getTiersString = (item)=>{ 
    //     let tiersItem = item.value.find(it => it.name === "specs");
    //     if (tiersItem && tiersItem.value) tiersItem = tiersItem.value.find(it => it.name === "Tiers");  
    //     return (tiersItem && tiersItem.value && !/ghq-is-empty/.test(tiersItem.value)) ? tiersItem.value.toLowerCase() : "";
    // }

    const getVersionString = (formatID)=>{
        let versionString = props.versions.find(it => it.name === formatID);
        if (versionString){
            versionString = versionString.value.find(it => it.name === "version_copy");
        }
        if (versionString){
            versionString = versionString.value;
        }
        if (!versionString){
            return "v1.0";
        } else {
            return versionString;
        }
    }

    const getVersionBg = (formatID)=>{
        let versionBg = props.versions.find(it => it.name === formatID);
        
        
        if (versionBg){
            versionBg = versionBg.value.find(it => it.name === "background_colour");
        }

        
        if (!versionBg){
            return "#d4d4d4";
        } else {
            let div = document.createElement("div");
            div.innerHTML = versionBg.value;
            if (div.firstChild){
                if (div.firstChild.nodeType === Node.TEXT_NODE) {
                    versionBg = div.innerText;
                } else if (div.firstChild.innerHTML){
                    versionBg = div.firstChild.innerHTML;
                }
            }
            
            return versionBg;
        }
    }

    const getPanelStyle = (featureName)=>{
        return {opacity:featureName === featureID || hoveringPanel === featureName ? 1 : .5};
    }

    let hasOptionalVideo = props.specs.find(it=>it.name==="Optional Video");
    hasOptionalVideo = hasOptionalVideo && /yes/i.test(hasOptionalVideo.value);

    // self select if only 1 option
    requestAnimationFrame(()=>{
        if (props.features.length === 1){
            setFeatureID(props.features[0].name)
        }
    })

return (
    <div className='section-templates'>
        {
            props.features.length > 1 ? (<><div className='step-number'><img src={stepNumber1} alt="" /></div>
            <h1>Select a {props.title} layout</h1></>) : (<></>)
        }
        <div className='content-panels' style={props.features.length === 1 ? {justifyContent:"center"} : {}}>
        {
            props.features.map((item, index) =>{
                // let tiersString = getTiersString(item);
                let versionString = getVersionString(item.name);
                let versionBg = getVersionBg(item.name);
                console.log(versionBg);
                
                return <div key={index} className='panel-select-highlight'>
                    <div data-id={item.name} onClick={selectTemplate} onMouseEnter={()=>setHoveringPanel(item.name)} onMouseLeave={()=>setHoveringPanel('')} style={getPanelStyle(item.name)} className="panel panel-select">
                        <div className="section-icon">
                            <img src={item.value.find(it => it.name === "icon").value} alt="" className="format-icon" />
                        </div>
                        <div className="section-heading">
                            <h3>{getName(item.name) || props.title}</h3>
                            {
                                hasOptionalVideo ? (<p>(Optional Video)</p>) : (false)
                            }
                        </div>

                        <div className="section-version-badge">
                            <div className="background" style={{backgroundColor:versionBg}}></div>
                            <h4 dangerouslySetInnerHTML={{__html:versionString}}></h4>
                        </div>
                       
                        {/* {
                            tiersString!=="" ? (<><div className="line"></div>
                            <div className="section-tiers">
                                <h4>Supported tiers <img data-id={item.name} onClick={selectTemplate} className='tiers-info-hover' src={toolTip} style={/ghq-is-empty/.test(tiersMessage) ? {display:'none'} : {display:'block'}} data-tooltip-id="tooltip" data-tooltip-html={tiersMessage} data-tooltip-place="top" data-tooltip-delay-show="500"/> </h4>
                                <div className="tier-icons">
                                    <img src={tierBronze} style={/bronze/.test(tiersString) ? {display:"block"} : {display:"none"}} alt="" className="tier-icon" />
                                    <img src={tierSilver} style={/silver/.test(tiersString) ? {display:"block"} : {display:"none"}} alt="" className="tier-icon" />
                                    <img src={tierGold} style={/gold/.test(tiersString) ? {display:"block"} : {display:"none"}} alt="" className="tier-icon" />
                                    <img src={tierPlatinum} style={/platinum/.test(tiersString) ? {display:"block"} : {display:"none"}} alt="" className="tier-icon" />
                                </div>
                            </div></> ) : (false)
                        } */}
                       
                    </div>
                </div>
            })
        }
        </div>
        <Tooltip id="tooltip" />
    </div>
)
}

export default TemplateSelect;