import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { Button } from 'pier-design-system';
import { InputCheckbox } from "pier-design-system"

function Modal(props) {

    const [checkboxState, setCheckboxState] = useState(false);

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background:'transparent',
          padding:'0',
          border:'none'
        },
        overlay:{
            background:"rgba(0,0,0,.2)",
            zIndex:300
        }
      };
  
    function closeModal() {
        setCheckboxState(false);
        props.setModalIsOpen(false)
    }

    const checkDoAction = ()=>{
        if (!props.checkbox || (props.checkbox && checkboxState)){
            props.action();
            closeModal();
        }
    }

    useMemo(()=>{
        ReactModal.setAppElement(document.body)
    }, [])

  return (
    <div>
        <ReactModal
        isOpen={props.modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        >
            <div className="panel panel-modal">
                <div className="section-heading">
                    <h2>{props.title}</h2>
                </div>
                <div className="section-copy" dangerouslySetInnerHTML={{__html: props.message}}></div>
                {
                    props.checkbox ? (<div className="section-cb">
                    <InputCheckbox onChange={(e)=>{setCheckboxState(e.target.checked)}} disabled={false} dark={false} error={false} partial={false}>
                        I understand
                    </InputCheckbox>
                    </div>) : (false)
                }
                <div className="section-cta">
                    <Button pill={true} onClick={() => {checkDoAction()}}>Confirm</Button>
                </div>
                <button className='panel-modal-close' onClick={()=>{closeModal()}}>
                    <span>x</span>
                </button>
            </div>
           
        </ReactModal>
    </div>
  )
}

export default Modal