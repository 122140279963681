import {React, useContext, useMemo} from 'react';
import '../App.css';

import stepNumber1 from '../imgs/step-number-1.png';
import stepNumber2 from '../imgs/step-number-2.png';

import { Button } from 'pier-design-system';

import { FeatureContext } from '../contexts/FeatureContext';
import { useSearchParams } from 'react-router-dom';

const TemplateCTAs = (props) => {

    const {featureID} = useContext(FeatureContext);

    const [searchParams, setSearchParams] = useSearchParams();

    let featureLinks = [];
    let ctaCopy = [];
    let availableLinkNames = useMemo(()=>{
        let arr = [];
        props.features.forEach((item)=>{
           let links = item.value.find(it => it.name==="links");
           if (links && links.value) {
                links = links.value;
                links.forEach((item2) =>{
                if (arr.indexOf(item2.name) < 0){
                    arr.push(item2.name);
                }
                });
           }
        })
        return arr;
    }, [props.features])

    

    props.features.forEach((item)=>{
        if (item.name === featureID){
            featureLinks = item.value.find(it => it.name === "links").value;
        }
    })

    props.ctaCopy.forEach((item)=>{
        if (item.name === featureID){
            ctaCopy = item.value;
        }
    })

    const getHref = (key)=>{
        if (key==="snippets"){
            let snippetsUrl = props.generalLinks && props.generalLinks.find(item => item.name === "snippets");
            snippetsUrl = snippetsUrl && snippetsUrl.value;
            return snippetsUrl;
        } else if (featureLinks.length && featureLinks.find(item => item.name === key)){
            let href = featureLinks.find(item => item.name === key).value;
            href = /(^http)|(^\/\/)/.test(href) ? href : undefined;
            return href
        } else {
            return undefined
        }
    }

    const getStyle = (key)=>{
        const isDevMode = searchParams.get("dev") === "true";
        const enabledCss = {opacity:1, pointerEvents:"auto"};
        const diabledCss = {opacity:.4, pointerEvents:"none"};
        const hideCss = {display:"none", pointerEvents:"none"};
        if (availableLinkNames.indexOf(key) < 0){
            return hideCss;
        } else if (getHref(key)){
            if (key==='html' || key==='html_b' || key==='blueprint'){
                if (isDevMode){
                    return enabledCss;
                } else {
                    return hideCss;
                }
            } else {
                return enabledCss;
            }
        } else {
            if (key==='html' ||  key==='blueprint'){
                if (isDevMode){
                    return diabledCss;
                } else {
                    return hideCss;
                }
            } else if (key==='preview_b' || key==="html_b") {
                return hideCss;
            } else {
                return diabledCss;
            }
        }
    }

    const downloadFile = (key)=>{
        const url = getHref(key);
        const link = document.createElement("a");
        link.style.cssText = "position:absolute;visibility:hidden;"
        link.href = url;
        link.setAttribute(
            "download",
            ""
        );
        if (featureID === "full_custom"){
            props.showFullCustomModal(function(){
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
        } else {
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } 
    }

    const getCtaCopy = (key)=>{
        let copyHTML = ctaCopy.find((it)=>{return it.name === key});
        let copy;
        if (copyHTML && copyHTML.value){
            let div = document.createElement("div");
            div.innerHTML = copyHTML.value;
            if (div.firstChild){
                if (div.firstChild.nodeType === Node.TEXT_NODE) {
                    copy = div.innerText;
                } else if (div.firstChild.innerHTML){
                    copy = div.firstChild.innerHTML;
                }
            }
        }
        let defaultCopy;
        if (/preview/.test(key)){
            defaultCopy = "Preview";
         } else if (/html/.test(key)){
            defaultCopy = "Download HTML";
        } else {
            defaultCopy = undefined;
        }
        console.log(copy);
        return copy || defaultCopy;
    }


return (
    <div className='section-ctas'>
        {
            props.features.length > 1 ? (<><div className='step-number'><img src={stepNumber2} alt="" /></div>
            <h1>Build or preview the selected layout</h1></>) : (<><div className='step-number' style={{display:"none"}}><img src={stepNumber1} alt="" /></div>
            <h1>Build or preview design</h1></>)
        }
        <div className='content-ctas'>
            {
                availableLinkNames.length > 2 ? 
                (<p>Create your design using our PSD layout. Export your design and convert it to an HTML build with our <span className='nowrap'>HTML files.</span><br/>Please contact our Creative Studio with any questions.</p>) :
                (<p>Create your design using our PSD layout. Please contact our Creative Studio with any questions.</p>)
            }
            <div className="content-ctas-inner">
                <a target="_blank" style={getStyle("preview")} href={getHref("preview")}><Button theme={"standard"}>{getCtaCopy("preview")}</Button></a>
                <a target="_blank" style={getStyle("preview_b")} href={getHref("preview_b")}><Button theme={"standard"}>{getCtaCopy("preview_b")}</Button></a>
                <Button style={getStyle("psd")} onClick={()=>{downloadFile("psd")}} theme={"standard"}>Download PSD</Button>
                <Button style={getStyle("html")} onClick={()=>{downloadFile("html")}} theme={"standard"}>{getCtaCopy("html")}</Button>
                <Button style={getStyle("html_b")} onClick={()=>{downloadFile("html_b")}} theme={"standard"}>{getCtaCopy("html_b")}</Button>
                <a target="_blank" style={getStyle("blueprint")} href={getHref("blueprint")}><Button theme={"standard"}>Open Blueprint</Button></a>
            </div>
            {
                getCtaCopy("under_ctas") ? (<p>{getCtaCopy("under_ctas")}</p>) : null
            }
            <div className="content-ctas-inner">
                <button className='text-button' style={getStyle("html")} onClick={()=>{downloadFile("snippets")}}>Download HTML Snippets</button>
            </div>
        </div>
    </div>
)
}

export default TemplateCTAs;