import {React, useContext} from 'react';
import '../App.css';

import { FeatureContext } from '../contexts/FeatureContext';
import { getName } from '../utilities';

const Specs = (props) => {

    const {featureID} = useContext(FeatureContext);
    const featureTitle = getName(featureID);
    const featureSpecs = props.features.find(it => it.name===featureID);

    const videoSpecs = props.features.find(it => it.name==="video");

    let videoSpecsContent = [];
    let featureSpecsContent = [];

    const omitValueNames = [
        "Tiers",
        "Optional Video"
        // "links",
        // "icon"
    ];

    let hasOptionalVideo = props.specs.find(it => it.name==="Optional Video");
    hasOptionalVideo = hasOptionalVideo && /yes/.test(hasOptionalVideo.value.toLowerCase());

    if (videoSpecs){
        let videoSpecsArr = videoSpecs.value.find(it => it.name === "specs").value || [];
        
        videoSpecsArr.forEach((item)=>{
            if (omitValueNames.indexOf(item.name) < 0){
                videoSpecsContent.push(item);
            }
        })
    }

    if (featureSpecs){
        let featureSpecsArr = featureSpecs.value.find(it => it.name === "specs").value || [];
        featureSpecsArr.forEach((item)=>{
            if (omitValueNames.indexOf(item.name) < 0){
                featureSpecsContent.push(item);
            }
        })
    }

return (
    <div className='section-specs'>
        <h1>Specifications</h1>
        <div className='content-specs'>
            <div>
                <h3>{props.title}</h3>
                <table>
                    <tbody>
                        {
                            props.specs.map((item, index)=>{
                                if (omitValueNames.indexOf(item.name) > -1) return false;
                                if (/http/.test(item.value)){
                                    return <tr key={index}><td><strong>{item.name}</strong></td><td><a href={item.value}>{item.name}</a></td></tr>
                                }
                                return <tr key={index}><td>{item.name !== "Sizes" ? (<strong>{item.name}</strong>) : (item.name)}</td><td dangerouslySetInnerHTML={{__html: item.value}}></td></tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div style={featureSpecsContent.length || props.sizes.length || (featureID !== "video" && videoSpecsContent.length) ? {display:"block"} : {display:"none"}}>
                {
                    featureSpecsContent.length ? (
                    <div className='inner-content'>
                        <h3>{featureTitle}</h3>
                            <table>
                            <tbody>
                                {
                                    featureSpecsContent.map((item, index)=>{
                                        return <tr key={index}><td><strong>{item.name}</strong></td><td dangerouslySetInnerHTML={{__html:item.value.replace(/\\n/, "<br/>")}}></td></tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    ) : (false)
                        
                }

                {
                    hasOptionalVideo && featureID !== "video" && videoSpecsContent.length ? (
                    <div className='inner-content vid' style={videoSpecsContent.length ? {display:"block"} : {display:"none"}}>
                        <h3>Optional Video</h3>
                            <table>
                            <tbody>
                                {
                                    videoSpecsContent.map((item, index)=>{
                                        return <tr key={index}><td><strong>{item.name}</strong></td><td dangerouslySetInnerHTML={{__html:item.value.replace(/\\n/, "<br/>")}}></td></tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    ) : (false)
                }
                
            </div>
            

        </div>
    </div>
)
}

export default Specs;