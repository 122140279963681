import {React, useContext, useEffect, useMemo, useState} from 'react';
import '../css/header.css';
import logo from '../imgs/logo.svg';
import logoSmall from '../imgs/logo-small.svg'
import { useNavigate, useSearchParams } from 'react-router-dom';

import {RegionSelectOptions, Regions, getRegionCode} from '../utilities';

import { RegionContext } from '../contexts/RegionContext';

import { BubbleMenu, BubbleMenuItem, BubbleMenuLabel } from 'pier-design-system';

const Header = (props) => {
    const navigate = useNavigate();

    const {specsRegion, setSpecsRegion} = useContext(RegionContext);

    const [searchParams, setSearchParams] = useSearchParams();

    const navigateToRegion = (region) => {
        let devModeParam = searchParams.get("dev");
        let linkQuery = devModeParam === "true" ? `?dev=${devModeParam}` : "";
        setSpecsRegion(region);
        switch (region) {
            case Regions.Region_EMEA:
                navigate(`/emea/${linkQuery}`);
                break;

            case Regions.Region_US:
                navigate(`/${linkQuery}`);
                window.location.href = "https://spec-sheet.gumgum.com/";
                // navigate("/us");
                break;

            case Regions.Region_UK:
                navigate(`/uk/${linkQuery}`);
                break;

            case Regions.Region_APAC:
                navigate(`/${linkQuery}`);
                window.location.href = "https://spec-sheet.gumgum.com/";
                // navigate("/apac");
                break;

            case Regions.Region_OTHER:
                navigate(`/${linkQuery}`);
                navigate("/help");
                break;

            default:
                navigate(`/${linkQuery}`);
        }
        setMenuOpen(false)

    }

    const goHome = ()=>{
        navigate("/");
    }

    let [selectValue, setSelectValue] = useState(undefined);

    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(()=>{
        setSelectValue(undefined)
        RegionSelectOptions.forEach((item)=>{
            if (!selectValue && item.value === specsRegion){
                setSelectValue(item);
            }
        })

        window.addEventListener("click", (e)=>{
            if (!e.target.classList.contains('open-menu-button') ){
                setMenuOpen(false);
            }
        })
    }, [specsRegion])


return (
    <header>
        <div className="section-left">
            <img className='logo desktop' src={logo} alt="logo" onClick={()=> {navigateToRegion()}} />
            <img className='logo-small mobile' src={logoSmall} alt="logo" onClick={()=> {navigateToRegion()}} />
        </div>
        <div className="section-middle">
            {
                props.title ? (<h2 dangerouslySetInnerHTML={{__html:props.title}}></h2>) : (false)
            }
            {
                props.sub_title ? (<h4 dangerouslySetInnerHTML={{__html:props.sub_title}}></h4>) : (false)
            }
        </div>
        <div className="section-right">
            {
                props.hideSelect ? (<></>) : (
                // <Select className='choose-region-select' value={selectValue} options={RegionSelectOptions} onChange={(obj) => {navigateToRegion(obj.value)}}/>
                <>
                <button className='open-menu-button' onClick={()=>{setMenuOpen(true)}}>
                    <h3>{specsRegion}</h3>
                    <div className="arrow-icon fa fa-chevron-down"></div>
                </button>
                    <BubbleMenu open={menuOpen} position="bottom right">
                        {
                            RegionSelectOptions.map((item, index)=>{
                                return <BubbleMenuItem key={index} onClick={(e) => {navigateToRegion(getRegionCode(e.target.innerText))}}>{item.label}</BubbleMenuItem>
                            })
                        }
                    </BubbleMenu>
                </>
                
                )
            }
             
        </div>
    </header>
)
}

export default Header;