import React, { useContext } from 'react';
import '../App.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Heading, Button } from 'pier-design-system';

import Header from '../components/Header';
import Footer from '../components/Footer';

import { useEffect } from 'react';

import stepNumber1 from '../imgs/step-number-1.png';
import formats from '../content/formats.json';
import { useSessionStorage } from 'usehooks-ts';
import { DevModeContext } from '../contexts/DevModeContext';


const FormatSelect = (props) => {

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const navigateToFormat = (link)=>{
        let devModeParam = searchParams.get("dev");
        let linkQuery = devModeParam === "true" ? `?dev=${devModeParam}` : "";
        navigate(`${link}/${linkQuery}`)
    }

    useEffect(()=>{
        document.querySelector("main").style.opacity = 1;
    }, [])

return (
    <div className='view-container choose-format'>
    <Header title={'GumGum Smart Designs<sup>TM</sup>'} sub_title={'Create high impact display ads with everything you need,<br/>all in one place.'}/>
    <main>
    <div className='section-formats'>
        {/* <div className='step-number'><img src={stepNumber1} alt="" /></div> */}
        {/* <Heading size={"md"}>Select a format to get started</Heading> */}
        <h1>Select a format to get started</h1>
        <div className='content-panels'>
        {
            props.formats.map((name, index) =>{
                let formatItem;
                formats.forEach((item)=>{
                    if (item.name === name){
                        formatItem = item;
                    }
                })
                if (formatItem){
                    return <div key={index} className="panel-select-highlight">
                        <div className="panel panel-select">
                            <div className="section-icon">
                                <img src={formatItem.value.icon} alt="" className="format-icon" />
                            </div>
                            <div className="line"></div>
                            <div className="section-heading">
                                <h3>{formatItem.value.full_name}</h3>
                                {
                                    formatItem.value.alt_name ? (<h4>({formatItem.value.alt_name})</h4>) : (false)
                                }
                            </div>
                            {/* <div className="section-copy">
                                <p>{item.summary_copy}</p>
                            </div> */}
                            <div className="section-cta">
                                <Button pill={true} onClick={() => {navigateToFormat(formatItem.value.link)}}>Confirm</Button>
                            </div>
                        </div>
                    </div>
                    
                }
            })
        }
        </div>
    </div>
    </main>
    <Footer/>
</div>
)
}

export default FormatSelect;