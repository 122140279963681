// const GuruServerAPI = "http://127.0.0.1:3050/";
const GuruServerAPI = "https://specs.gumgum.com/api/v1";

const CardIds = {
    "site_copy": "5b693cda-5e6e-4e99-8f15-25a503ca4a79",

    "desktop_skin":"55441ef0-7f6d-462d-a897-573091499ff4",
    "mobile_skin":"0b6fd296-946e-49fa-b51f-4d140c842245",
    "mobile_scroller" : "fb8e073e-a7f6-4dcf-8fe0-0213823a8635",
    "inscreen_expandable_desktop":"fd7ae8b6-5234-49b7-bda6-57c7bebba945",
    "inscreen_expandable_mobile":"baf794e5-d7e9-4711-952b-5070d588f5cf",
    "inscreen_expandable":"b0e97c77-32db-4fca-8d8c-1af2a0a41d0f",
    "inscreen_expandable_video":"cf1711fc-4638-4f33-a0ca-51f21f94c740",
    "inimage_expandable":"81369a79-f1ca-4385-847b-d565ba337260",
    "ingame":"383fa8ce-7e37-491a-99d3-8ec385a0cd77",
    "six_second_canvas" : "34384dca-68ad-4e77-941b-4813b0dc1d2b",

    "hangtime":"dd8252ce-6fb4-4c11-9d64-6a2d04a681e3",
    "hoverboard":"a444c6ad-61f2-4c01-b080-0f732acfdcc9",

    "features_desktop":"29a0e0b5-6eae-48dc-9a9a-b6d1d289d100",
    "features_mobile":"72009455-2f9f-4cc3-be8d-0b90f103a7c4",
    "features_mobile_xyz": "a5dddf36-05de-4c03-a4b1-9d4af4b4bfb9"
}

const cachedCards = [];
const regexReturnChar = /(\r\n|\n|\r)/;

export const getGuruCard = (cardId, callback) => {
    cardId = CardIds[cardId] || cardId;

    let cachedCard = cachedCards.find(item=> item.cardId === cardId );
    if (cachedCard){
        callback(cachedCard.tablesData);
        return;
    }


fetch(`${GuruServerAPI}/cards/${cardId}/extended`, {method: 'get'})
// fetch(`${GuruServerAPI}/cards/${cardId}/extended`, {
//     method: 'get',
//     headers: {
//         'Collection': 'GLOBAL_SPECS'
//     }
// })
        .then(response => {
            return response.json()
        })
        .then(json => {
            let htmlFrag = document.createElement("DIV");
            htmlFrag.innerHTML = json.content;
            let tables = htmlFrag.querySelectorAll("tbody");
            let tablesData = [];
            Array.prototype.forEach.call(tables, (table)=>{
                let rows = table.querySelectorAll("tr");
                var data, innerValue, colHeadings = [];
                Array.prototype.forEach.call(rows, (row, index)=>{
                    var cols = row.querySelectorAll("td");
                    if (index === 0){
                        innerValue = [];
                        Array.prototype.forEach.call(cols, (col)=>{
                            // double check this regex test
                            col.innerText && col.innerText.length > 1 && !regexReturnChar.test(col.innerText) && colHeadings.push(col.innerText.toLowerCase().replace(/\s/, "_"))
                        });
                        data = {name:colHeadings[0], value:innerValue};
                        tablesData.push(data);
                    } else {
                        let value = [], subvalue;
                        Array.prototype.forEach.call(cols, (col, index2)=>{
                            if (index2 > 0){
                                let aEl = col.querySelector("A");
                                if (aEl){
                                    subvalue = aEl.href;
                                } else {
                                    subvalue = col.innerHTML;
                                }
                                value.push({name:colHeadings[index2], value:subvalue})
                            }
                        });
                        if (colHeadings.length === 1){
                            value = subvalue;
                        }
                        let dataEntry = {name:cols[0].innerText, value:value};
                        innerValue.push(dataEntry);
                    }
                })
            })
            cachedCards.push({cardId:json.id, tablesData:tablesData})
            callback(tablesData);
        })
        .catch(err => console.log(err));
}