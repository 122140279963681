import React, { useEffect } from 'react';
import '../css/footer.css';
import { useNavigate } from 'react-router-dom';
import { Button } from 'pier-design-system';

const Footer = (props) => {
    const navigate = useNavigate();

return (
    <footer>
        <div className="section-left"></div>
        <div className="section-middle">
            <p className="coalition-copy">All GumGum ad formats are compliant with the Better Ads Standards as defined by <a href="https://www.betterads.org/standards/" target='_blank' rel='noopener'>The Coalition for Better Ads</a>.</p>
            <p>© 2024 GumGum, Inc. All rights reserved.</p>
        </div>
        <div className="section-right">
        <Button title="Button" pill={true} className={'cta-help'} onClick={()=>{navigate("/help")}}>Help</Button>
        </div>
    </footer>
)
}

export default Footer;